<template>
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <form @submit.prevent="changePassword()">
          <div class="card-header">
            <h4>Change Password</h4>
          </div>
          <div class="card-body pb-0">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Current Password</label>
                  <input type="password" class="form-control" v-model="fields.current_password" placeholder="************"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>New Password</label>
                  <input type="password" class="form-control" v-model="fields.new_password" placeholder="************"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Confirm new Password</label>
                  <input type="password" class="form-control" v-model="fields.confirm_new_password" placeholder="************"/>
                  <small style="color: red" v-if="fields.confirm_new_password.length > 0 && !passwordMatch">
                    <p class="mb-0">Confirm password doesn't match</p>
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right pt-0">
            <button class="btn btn-primary" :class="{ 'btn-progress': loading }">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import GeneralHelpers from "@/functions/actions/GeneralRequest";
export default {
  name: "changePassword",
  computed: {
    ...mapState(["loading"]),
    passwordMatch() {
      return this.fields.confirm_new_password !== this.fields.new_password ? false : true
    }
  },
  mounted() {},
  data() {
    return {
      fields: {
        current_password: "",
        new_password: "",
        confirm_new_password: ""
      },
    };
  },
  methods: {
    async changePassword() {
      if (this.validateData()) {
        const response = await GeneralHelpers.changePassword(this.fields);
        if (response.status) {
          this.showAlert("Success", "Password has been reset successfully", "success")
          this.$store.dispatch("logout")
          this.$router.push({
            name: "Login",
          });
        } else {
          this.showAlert(
            "Error occured",
            `${response.message}`,
            "error"
          );
        }
      }
    },
    validateData() {
      if (this.fields.current_password) {
        if (this.fields.new_password) {
          if (this.fields.confirm_new_password) {
            if (this.fields.new_password == this.fields.confirm_new_password) {
              return true
            } else {
              this.showAlert(
                "Validation error",
                "Password confirmation doesn'\t match",
                "warning"
              );
            }
          } else {
            this.showAlert(
              "Validation error",
              "Confirm new password",
              "warning"
            );
          }
        } else {
          this.showAlert(
            "Validation error",
            "Enter a new password",
            "warning"
          );
        }
      } else {
        this.showAlert(
          "Validation error",
          "Enter current password",
          "warning"
        );
      }
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>
<style lang="">
</style>